.websiteCard {
  display: flex;
  width: 120px;
  height: 160px;
  flex-flow: column wrap;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  align-items: center;
  text-decoration: none;
  color: inherit;
  gap: 0.5rem;
}

.cardImage {
  display: block;
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  flex-shrink: 0;
}

.cardTitle {
  font-size: 1rem;
  white-space: nowrap;
}

/* Override styles when inside nav-links */
.nav-links .websiteCard {
  height: auto;
  margin: 0;
}

.nav-links .cardImage {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .nav-links .websiteCard {
    width: 100%;
    justify-content: center;
  }
}