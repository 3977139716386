.topNavBar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: var(--navbar-height);
    z-index: 9999;
    background-color: rgb(255, 255, 255);
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: calc(210mm + 40px); /* Account for mainBody's 20px padding on each side */
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px; /* Match mainBody padding */
    box-sizing: border-box;
}

.brand {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  gap: 0.5rem;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: #000F;
  transition: color 0.2s;
}

.nav-link:hover {
  color: #007bff;
}

.nav-link.active {
  font-weight: bold;
  color: #007bff;
}

/* Social media icons in navbar */
.nav-links .websiteCard {
  width: auto;
  height: auto;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.nav-links .websiteCard .cardImage {
  width: 24px;
  height: 24px;
  margin: 0;
}

/* Hide the span by default for desktop */
.nav-links .websiteCard span {
  display: none;
}

.menu-button {
  display: none;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
}

.menu-button div {
  width: 25px;
  height: 2px;
  background-color: black;
  margin: 5px 0;
  transition: all 0.3s;
}

@media (max-width: 768px) {
  .menu-button {
    display: block;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: rgb(255, 255, 255);
    padding: 0;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  .nav-links.open {
    max-height: 400px; /* Increased from 300px to accommodate all icons */
    opacity: 1;
    padding: 1rem;
    box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.4);
  }

  /* Chrome-specific style for the expanded menu */
  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    .nav-links.open {
      max-height: 450px;  /* Increased height specifically for Chrome */
    }
  }

  .nav-link {
    padding: 0.5rem 0;
    width: 100%;
    text-align: center;
  }

  .menu-button.open div:first-child {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .menu-button.open div:nth-child(2) {
    opacity: 0;
  }

  .menu-button.open div:last-child {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  /* Mobile-specific styles for website cards */
  .nav-links .websiteCard {
    width: 100%;
    padding: 0.5rem 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    text-decoration: none;
    color: #000;
  }

  .nav-links .websiteCard:hover {
    color: #007bff;
  }

  /* Show the spans in mobile menu */
  .nav-links .websiteCard span {
    display: inline;
    font-size: 1rem;
  }

  .nav-links .websiteCard .cardImage {
    width: 20px;
    height: 20px;
  }
}